import React from "react"

import { CustomLogo } from "../components/Headers/CustomLogo"
import { BlackHalvaLogo } from "../components/Headers/Logos"

import Layout from "../components/Layouts/secondaryPage"
import Finish from "../components/Finish"

export default function FinishPage() {
  return (
    <Layout>
      <CustomLogo withButton={false} Logo={BlackHalvaLogo} />
      <Finish />
    </Layout>
  )
}
